import { transportation } from '@/axios'

export default {
  uploadRequiredDocsFiles (payload) {
    return transportation().patch('companies', payload)
  },
  getRequirementsDocuments () {
    return transportation().get('required-docs?filters[type]=company')
  },
  getCompanyInfo () {
    return transportation().get('companies/info')
  }
}
